import appStrings from '@/app/utility/string.utility.js';
import commonHelper from '@/app/utility/common.helper.utility';
import addSource from './addSource'
export default {
    name: 'Source',
    components: {
        addSource
    },
    watch: {
        currentPage: function () {
            this.getSourceData();
        },
        perPage: function () {
            this.currentPage = 1;
            this.getSourceData();
        }
    },
    data() {
        return {
            totalRows: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            currentPage: 1,
            loader: false,
            editMode: false,
            module: { value: null, text: null },
            subModule: { value: null, text: null },
            legalEntity: { value: null, text: null },
            operatingUnit: { value: null, text: null },
            sourceName: null,
            sourceType: { value: null, text: null },
            defaultFlag: null,
            advanceFlag: null,
            unsubscribe: null,
            showValueSetModal: false,
            parent_value_set_id: null,
            depVsetParam: null,
            sourceData: [],
            sourceFields: [
                {
                    key: 'source_name'
                },
                {
                    key: 'source_type'
                },
                {
                    key: 'module_name',
                    label: 'Module'
                },
                {
                    key: 'sub_module_name',
                    label: 'Sub Module'
                },
                {
                    key: 'le_name',
                    label: 'Legal Entity'
                },
                {
                    key: 'ou_name',
                    label: 'Operating Unit'
                },
                {
                    key: 'created_by'
                },
                {
                    key: 'creation_date'
                },
                {
                    key: 'last_updated_by'
                },
                {
                    key: 'last_update_date'
                },
                {
                    key: 'default_flag'
                },
                {
                    key: 'advance_flag'
                }
            ],
            showAddSourceModal: false,
            sourceRowDetail: null
        };
    },
    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'add') {
                    this.showAddSourceModal = true;
                    this.sourceRowDetail = null;
                }
                // if (actionName === 'download' && !this.showBankModeModal) {
                //   this.loader = true;
                //   /**
                //    * @param(payload, 'action name', 'file name')
                //    */
                //   const downloadpayload = { ...this.payload };
                //   downloadpayload._limit = this.totalRows;
                //   this.downloadExcel.downloadData(
                //     downloadpayload,
                //     'bankMpngMode/getBankMethodDetail',
                //     'bankmodemapping',
                //     () => (this.loader = false)
                //   );
                // }
            }
        });
    },
    methods: {
        isModuleAvail(module) {
            if (module) {
                this.openValueSetModal('SUB_MODULE')
            } else {
                alert('Please Select Module First')
            }
        },
        validateSourceType() {
            if (this.legalEntity.value && this.operatingUnit.value) {
                this.openValueSetModal('SOURCE_TYPE')
            } else {
                alert('Please Select Legal Entity and Operating Unit')
            }
        },
        getSourceData() {
            this.payload = {
                _page: this.currentPage - 1,
                _limit: this.perPage,
                module_id: this.module.value,
                sub_module_id: this.subModule.value,
                le_id: this.legalEntity.value,
                ou_id: this.operatingUnit.value,
                source_type: this.sourceType.text,
                source_name: this.sourceName,
                default_flag: this.defaultFlag,
                advance_flag: this.advanceFlag
            };
            this.loader = true;
            this.$store
                .dispatch('source/getSourceData', this.payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.sourceData = response.data.data.page;
                        this.totalRows = response.data.data.total_elements;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        openValueSetModal(vsetCode) {
            this.vsetCode = vsetCode;
            this.parent_value_set_id = null;
            this.depVsetParam = null;
            if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.parent_value_set_id = this.legalEntity.value
            } else if (this.vsetCode === appStrings.VALUESETTYPE.SUB_MODULE) {
                this.parent_value_set_id = this.module.value
            } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
                this.parent_value_set_id = this.operatingUnit.value
                this.depVsetParam = this.legalEntity.value
            }
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        selectedvalueSet(item) {
            if (this.vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
                this.module.text = item.value_code;
                this.module.value = item.value_set_dtl_id;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.SUB_MODULE) {
                this.subModule.text = item.value_code;
                this.subModule.value = item.value_set_dtl_id;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
                this.legalEntity.text = item.value_code;
                this.legalEntity.value = item.value_set_dtl_id;
                this.operatingUnit.text = null;
                this.operatingUnit.value = null;
                this.sourceType.text = null;
                this.sourceType.value = null;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.operatingUnit.text = item.value_code;
                this.operatingUnit.value = item.value_set_dtl_id;
                this.sourceType.text = null;
                this.sourceType.value = null;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
                this.sourceType.text = item.value_code;
                this.sourceType.value = item.value_set_dtl_id;
            }
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        clearFilters() {
            this.module = { value: null, text: null };
            this.subModule = { value: null, text: null };
            this.legalEntity = { value: null, text: null };
            this.operatingUnit = { value: null, text: null };
            this.sourceType = { value: null, text: null };
            this.defaultFlag = null,
            this.advanceFlag = null,
            this.sourceName = null;
            this.sourceData = [];
            this.totalRows = null;
            this.currentPage = 1;
            this.parent_value_set_id = null;
            this.depVsetParam = null;
        },
        clearVsetValues(vsetCode) {
            if (vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
                this.module.text = null;
                this.module.value = null;
                this.subModule.text = null;
                this.subModule.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.SUB_MODULE) {
                this.subModule.text = null;
                this.subModule.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
                this.legalEntity.text = null;
                this.legalEntity.value = null;
                this.operatingUnit.text = null;
                this.operatingUnit.value = null;
                this.sourceType.text = null;
                this.sourceType.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.operatingUnit.text = null;
                this.operatingUnit.value = null;
                this.sourceType.text = null;
                this.sourceType.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE) {
                this.sourceType.text = null;
                this.sourceType.value = null;
            }
        },
        rowSelected(item) {
            this.sourceRowDetail = item;
            this.showHideAddSourceModal(true);
        },
        showHideAddSourceModal(flag) {
            this.showAddSourceModal = flag;
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};
