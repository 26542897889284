import appStrings from '@/app/utility/string.utility.js';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
    name: 'Source',
    props: ['sourceRowDetail'],
    components: {
    },
    data() {
        return {
            totalRows: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            currentPage: 1,
            loader: false,
            isSuccess: false,
            showAlert: false,
            responseMsg: '',
            editMode: false,
            sourceHdrId: 0,
            module: { value: null, text: null },
            subModule: { value: null, text: null },
            legalEntity: { value: null, text: null },
            operatingUnit: { value: null, text: null },
            sourceType: { value: null, text: null },
            sourceName: null,
            defaultFlag: false,
            advanceFlag: false,
            unsubscribe: null,
            showValueSetModal: false,
            parent_value_set_id: null
        };
    },
    mounted() {
    if (this.sourceRowDetail) {
        this.fillRecordFromSource(this.sourceRowDetail);
      } else {
        this.editMode = true;
      }
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'save' && this.editMode) {
                    this.addEditSource();
                }
                if (actionName === 'update') {
                    this.editMode = true;
                  }
            }
        });
    },
    methods: {
        isModuleAvail(module) {
            if (module) {
                this.openValueSetModal('SUB_MODULE')
            } else {
                alert('Please Select Module First')
            }
        },
        fillRecordFromSource(item) {
            this.sourceHdrId = item.source_hdr_id;
            this.advanceFlag = item.advance_flag;
            this.defaultFlag = item.default_flag;
            this.legalEntity.text = item.le_name;
            this.legalEntity.value = item.le_id;
            this.module.text = item.module_name;
            this.module.value = item.module_id;
            this.operatingUnit.text = item.ou_name;
            this.operatingUnit.value = item.ou_id;
            this.sourceName = item.source_name;
            this.sourceType.text = item.source_type
            this.subModule.value = item.sub_module_id
            this.subModule.text = item.sub_module_name
        },
        addEditSource() {
            const payload = {
                source_hdr_id: this.sourceHdrId,
                module_id: this.module.value,
                sub_module_id: this.subModule.value,
                org_le_id: this.legalEntity.value,
                org_ou_id: this.operatingUnit.value,
                source_name: this.sourceName,
                source_type: this.sourceType.text,
                default_flag: this.defaultFlag,
                advance_flag: this.advanceFlag
            };
            this.loader = true;
            this.$store
                .dispatch('source/addEditSource', payload)
                .then(response => {
                    this.loader = false;
                    this.showAlert = true;
                    if (response.status === 201) {
                        this.editMode = false;
                        this.isSuccess = true;
                        this.responseMsg = response.data.message;
                    } else {
                        this.isSuccess = false;
                        this.responseMsg = response.response.data.message;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    this.showAlert = true;
                    this.isSuccess = false;
                    this.responseMsg = appStrings.autoFailedMsg;
                });
        },
        openValueSetModal(vsetCode) {
            this.vsetCode = vsetCode;
            this.parent_value_set_id = null;
            if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.parent_value_set_id = this.legalEntity.value
            } else if (this.vsetCode === appStrings.VALUESETTYPE.SUB_MODULE) {
                this.parent_value_set_id = this.module.value
            } 
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        selectedvalueSet(item) {
            if (this.vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
                this.module.text = item.value_code;
                this.module.value = item.value_set_dtl_id;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.SUB_MODULE) {
                this.subModule.text = item.value_code;
                this.subModule.value = item.value_set_dtl_id;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
                this.legalEntity.text = item.value_code;
                this.legalEntity.value = item.value_set_dtl_id;
                this.operatingUnit.text = null;
                this.operatingUnit.value = null;
                this.sourceType.text = null;
                this.sourceType.value = null;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.operatingUnit.text = item.value_code;
                this.operatingUnit.value = item.value_set_dtl_id;
                this.sourceType.text = null;
                this.sourceType.value = null;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE_NEW) {
                this.sourceType.text = item.value_meaning;
                this.sourceType.value = item.value_code;
            }
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        clearVsetValues(vsetCode) {
            if (vsetCode === appStrings.VALUESETTYPE.MODULE_LIST) {
                this.module.text = null;
                this.module.value = null;
                this.subModule.text = null;
                this.subModule.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.SUB_MODULE) {
                this.subModule.text = null;
                this.subModule.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
                this.legalEntity.text = null;
                this.legalEntity.value = null;
                this.operatingUnit.text = null;
                this.operatingUnit.value = null;
                this.sourceType.text = null;
                this.sourceType.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.operatingUnit.text = null;
                this.operatingUnit.value = null;
                this.sourceType.text = null;
                this.sourceType.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.SOURCE_TYPE_NEW) {
                this.sourceType.text = null;
                this.sourceType.value = null;
            }
        },
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};
